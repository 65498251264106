import styles from './HomeSectionContent.module.css';
import { HomeSectionInfo } from '../../type';

interface Props {
  info: HomeSectionInfo;
  isHovered: boolean;
  isMobile: boolean;
}
function HomeSectionContent(props: Props) {
  const { info, isHovered, isMobile } = props;

  return (
    <div className={styles.container}>
      <img
        className={styles.title}
        src={info.titleImage}
        alt={info.title}
        style={{ opacity: isHovered || isMobile ? 1 : 0.32 }}
      />
      {(isHovered || isMobile) && (
        <p className={styles.description}>
          {!isMobile && info.description}
          {isMobile && (info.mobileDescription ?? info.description)}
        </p>
      )}
    </div>
  );
}

export default HomeSectionContent;
