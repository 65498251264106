import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Gallery from './Page/Gallery/Gallery';
import Home from './Page/Home/Home';

function App() {
  return (
    <HashRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/gallery' element={<Gallery />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
