import styles from './HomeSection.module.css';
import { HomeSectionInfo } from 'type';
import { useMediaQuery } from 'react-responsive';
import HomeSectionContent from '../HomeSectionContent/HomeSectionContent';
import { sections } from '../../Page/Home/Home';

interface Props {
  info: HomeSectionInfo;
  hoveredSection: HomeSectionInfo;
  onHover: Function;
}
function HomeSection(props: Props) {
  const { info, hoveredSection, onHover } = props;
  const isMobile = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  return (
    <section
      className={styles.container}
      onMouseOver={() => {
        if (isMobile) {
          return;
        }
        onHover(info);
      }}
      onMouseLeave={() => {
        if (isMobile) {
          return;
        }
        onHover(sections[0]);
      }}
      onClick={() => window.open(info.url, '__blank')}
      style={{
        backgroundImage: isMobile
          ? `url(${info.mobileBackgroundImage})`
          : 'none',
        backgroundPosition: isMobile ? 'center' : 'none',
        backgroundSize: isMobile ? 'cover' : 'none',
        backgroundPositionY:
          isMobile && info.title === 'megaroad' ? 'bottom' : 'center',
      }}
    >
      <HomeSectionContent
        info={info}
        isHovered={hoveredSection.title === info.title}
        isMobile={isMobile}
      />
    </section>
  );
}

export default HomeSection;
