import logo from 'assets/images/logo.svg';
import SnsButton from '../SnsButton/SnsButton';
import twitter from 'assets/images/twitter.svg';
import discord from 'assets/images/discord.svg';
import styles from './Header.module.css';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import mintMobileButton from '../../assets/images/mintMobileButton.png';
import mintHoveredButton from '../../assets/images/mintHoveredButton.png';
import mintButton from '../../assets/images/mintButton.png';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
function Header() {
  const location = useLocation();
  const [isHoveredMintButton, setIsHoveredMintButton] = useState(false);
  // const [mintEndDate, setMintEndDate] = useState(new Date());
  const isMobile = useMediaQuery({
    query: '(max-width: 1024px)',
  });
  // // //TODO: production url 변경
  // const getMintDate = async () => {
  //   try {
  //     const response = await axios(
  //       'https://staged.blackmarket.fidelion.io/api/v0/empire/periods',
  //     );
  //     const endDate = response.data.airdrop.endDate;
  //     // setMintEndDate(new Date(endDate));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  //TODO: url 다시 확인
  const openMintSite = () => {
    window.open('https://mint.fidelion.io', '__blank');
  };
  //
  // useEffect(() => {
  //   getMintDate();
  // }, []);
  useEffect(() => {
    switch (location.pathname) {
      case '/':
        document.title = 'Fidelion | home';
        break;
      case '/gallery':
        document.title = 'Fidelion | gallery';
    }
  }, [location.pathname]);

  return (
    <header
      className={`${styles.header} ${location.pathname === '/gallery' && styles.gallery}`}
    >
      {isMobile ? (
        <div
          onClick={openMintSite}
          style={{
            cursor: 'pointer',
            position: 'fixed',
            bottom: 10,
            right: 30,
            width: 100,
            zIndex: 10,
          }}
        >
          <img src={mintMobileButton} style={{ width: 120, height: 70 }} />
        </div>
      ) : (
        <>
          {/*<FlipClockCountdown endDate={new Date(mintEndDate)} />*/}
          <div
            onClick={openMintSite}
            style={{
              cursor: 'pointer',
              height: 80,
              display: 'flex',
              alignContent: 'center',
              zIndex: 100,
              position: 'fixed',
              left: 0,
              right: 0,
              margin: 'auto',
              width: 'fit-content',
              top: 2,
            }}
            onMouseOver={() => setIsHoveredMintButton(true)}
            onMouseLeave={() => setIsHoveredMintButton(false)}
          >
            <img
              src={isHoveredMintButton ? mintHoveredButton : mintButton}
              style={{
                transition: 'src 1.25s ease-in-out',
                width: 250,
                height: 80,
              }}
            />
          </div>
        </>
      )}

      <Link to={'/'}>
        <img className={styles.logo} src={logo} alt='fidelion-logo' />
      </Link>
      <div className={styles.buttonContainer}>
        <Link className={styles.galleryButton} to={'/gallery'}>
          Gallery
        </Link>
        <SnsButton
          icon={twitter}
          alt='twitter'
          url={'https://twitter.com/FidelionNFT'}
        />
        <SnsButton
          icon={discord}
          alt='discord'
          url={'https://discord.com/invite/officialfidelion'}
        />
      </div>
    </header>
  );
}

export default Header;
