import { Image } from 'react-grid-gallery';
import React from 'react';

export interface CustomImage extends Image {
  original: string;
}

const customOverlay = React.createElement('div', {
  style: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(0,0,0, 0.3)',
  },
});

// const url = window.location.href.split('/#/gallery')[0];
const url = process.env.PUBLIC_URL;
export const fidelionImages: CustomImage[] = [
  {
    src: `${url}/thumbnail/fidelion/1_2055x3352.jpg`,
    original: `${url}/origin/fidelion/1_2055x3352.jpg`,
    width: 2055,
    height: 3352,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/2_1864x4000.jpg`,
    original: `${url}/origin/fidelion/2_1864x4000.jpg`,
    width: 1864,
    height: 4000,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/3_2268x3863.jpg`,
    original: `${url}/origin/fidelion/3_2268x3863.jpg`,
    width: 2268,
    height: 3863,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/4_3824x2233.jpg`,
    original: `${url}/origin/fidelion/4_3824x2233.jpg`,
    width: 3824,
    height: 2233,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/5_3840x5098.jpg`,
    original: `${url}/origin/fidelion/5_3840x5098.jpg`,
    width: 3840,
    height: 5098,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/6_2295x2175.jpg`,
    original: `${url}/origin/fidelion/6_2295x2175.jpg`,
    width: 2295,
    height: 2175,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/7_4084x762.jpg`,
    original: `${url}/origin/fidelion/7_4084x762.jpg`,
    width: 4084,
    height: 762,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/8_2712x3375.jpg`,
    original: `${url}/origin/fidelion/8_2712x3375.jpg`,
    width: 2712,
    height: 3375,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/9_1650x3861.jpg`,
    original: `${url}/origin/fidelion/9_1650x3861.jpg`,
    width: 1650,
    height: 3861,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/10_2591x3122.jpg`,
    original: `${url}/origin/fidelion/10_2591x3122.jpg`,
    width: 2591,
    height: 3122,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/11_2744x3809.jpg`,
    original: `${url}/origin/fidelion/11_2744x3809.jpg`,
    width: 2744,
    height: 3809,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/12_3495x2013.jpg`,
    original: `${url}/origin/fidelion/12_3495x2013.jpg`,
    width: 3495,
    height: 2013,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/13_2748x4096.jpg`,
    original: `${url}/origin/fidelion/13_2748x4096.jpg`,
    width: 2748,
    height: 4096,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/14_3198x2563.jpg`,
    original: `${url}/origin/fidelion/14_3198x2563.jpg`,
    width: 3198,
    height: 2563,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/15_6624x4752.jpg`,
    original: `${url}/origin/fidelion/15_6624x4752.jpg`,
    width: 6624,
    height: 4752,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/16_1314x2320.jpg`,
    original: `${url}/origin/fidelion/16_1314x2320.jpg`,
    width: 1314,
    height: 2320,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/17_1402x1000.jpg`,
    original: `${url}/origin/fidelion/17_1402x1000.jpg`,
    width: 1402,
    height: 1000,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/18_1284x1655.jpg`,
    original: `${url}/origin/fidelion/18_1284x1655.jpg`,
    width: 1284,
    height: 1655,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/19_3738x1646.jpg`,
    original: `${url}/origin/fidelion/19_3738x1646.jpg`,
    width: 3738,
    height: 1646,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/20_1384x1328.jpg`,
    original: `${url}/origin/fidelion/20_1384x1328.jpg`,
    width: 1384,
    height: 1328,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/21_6000x2534.jpg`,
    original: `${url}/origin/fidelion/21_6000x2534.jpg`,
    width: 6000,
    height: 2534,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/22_3780x4096.jpg`,
    original: `${url}/origin/fidelion/22_3780x4096.jpg`,
    width: 3780,
    height: 4096,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/23_2312x2312.jpg`,
    original: `${url}/origin/fidelion/23_2312x2312.jpg`,
    width: 2312,
    height: 2312,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/24_4096x4096.jpg`,
    original: `${url}/origin/fidelion/24_4096x4096.jpg`,
    width: 4096,
    height: 4096,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/26_1900x1080.jpg`,
    original: `${url}/origin/fidelion/26_1900x1080.jpg`,
    width: 1900,
    height: 1080,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/27_1216x2302.jpg`,
    original: `${url}/origin/fidelion/27_1216x2302.jpg`,
    width: 1216,
    height: 2302,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/28_3000x3215.jpg`,
    original: `${url}/origin/fidelion/28_3000x3215.jpg`,
    width: 3000,
    height: 3215,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/29_2480x3508.jpg`,
    original: `${url}/origin/fidelion/29_2480x3508.jpg`,
    width: 2480,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/30_2480x3508.jpg`,
    original: `${url}/origin/fidelion/30_2480x3508.jpg`,
    width: 2480,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/31_728x728.jpg`,
    original: `${url}/origin/fidelion/31_728x728.jpg`,
    width: 728,
    height: 728,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/32_1678x1290.jpg`,
    original: `${url}/origin/fidelion/32_1678x1290.jpg`,
    width: 1678,
    height: 1290,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/33_2718x1136.jpg`,
    original: `${url}/origin/fidelion/33_2718x1136.jpg`,
    width: 2718,
    height: 1136,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/36_4961x3508.jpg`,
    original: `${url}/origin/fidelion/36_4961x3508.jpg`,
    width: 4961,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/38_1208x2092.jpg`,
    original: `${url}/origin/fidelion/38_1208x2092.jpg`,
    width: 1208,
    height: 2092,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/41_2276x2160.jpg`,
    original: `${url}/origin/fidelion/41_2276x2160.jpg`,
    width: 2276,
    height: 2160,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/42_2607x1573.jpg`,
    original: `${url}/origin/fidelion/42_2607x1573.jpg`,
    width: 2607,
    height: 1573,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/43_4961x3508.jpg`,
    original: `${url}/origin/fidelion/43_4961x3508.jpg`,
    width: 4961,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/44_1568x2254.jpg`,
    original: `${url}/origin/fidelion/44_1568x2254.jpg`,
    width: 1568,
    height: 2254,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/45_1580x936.jpg`,
    original: `${url}/origin/fidelion/45_1580x936.jpg`,
    width: 1580,
    height: 936,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/46_1644x4096.jpg`,
    original: `${url}/origin/fidelion/46_1644x4096.jpg`,
    width: 1644,
    height: 4096,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/47_2886x1997.jpg`,
    original: `${url}/origin/fidelion/47_2886x1997.jpg`,
    width: 2886,
    height: 1997,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/48_5645x3508.jpg`,
    original: `${url}/origin/fidelion/48_5645x3508.jpg`,
    width: 5645,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/49_1627x720.jpg`,
    original: `${url}/origin/fidelion/49_1627x720.jpg`,
    width: 1627,
    height: 720,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/50_2188x2220.jpg`,
    original: `${url}/origin/fidelion/50_2188x2220.jpg`,
    width: 2188,
    height: 2220,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/54_2306x2307.jpg`,
    original: `${url}/origin/fidelion/54_2306x2307.jpg`,
    width: 2306,
    height: 2307,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/55_3216x1278.jpg`,
    original: `${url}/origin/fidelion/55_3216x1278.jpg`,
    width: 3216,
    height: 1278,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/56_1833x2589.jpg`,
    original: `${url}/origin/fidelion/56_1833x2589.jpg`,
    width: 1833,
    height: 2589,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/57_2992x1716.jpg`,
    original: `${url}/origin/fidelion/57_2992x1716.jpg`,
    width: 2992,
    height: 1716,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/58_5259x4000.jpg`,
    original: `${url}/origin/fidelion/58_5259x4000.jpg`,
    width: 5259,
    height: 4000,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/61_5198x2048.jpg`,
    original: `${url}/origin/fidelion/61_5198x2048.jpg`,
    width: 5198,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/63_5739x3880.jpg`,
    original: `${url}/origin/fidelion/63_5739x3880.jpg`,
    width: 5739,
    height: 3880,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/64_1802x3070.jpg`,
    original: `${url}/origin/fidelion/64_1802x3070.jpg`,
    width: 1802,
    height: 3070,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/65_1584x998.jpg`,
    original: `${url}/origin/fidelion/65_1584x998.jpg`,
    width: 1584,
    height: 998,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/66_2276x2160.jpg`,
    original: `${url}/origin/fidelion/66_2276x2160.jpg`,
    width: 2276,
    height: 2160,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/67_2846x1424.jpg`,
    original: `${url}/origin/fidelion/67_2846x1424.jpg`,
    width: 2846,
    height: 1424,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/69_2108x2775.jpg`,
    original: `${url}/origin/fidelion/69_2108x2775.jpg`,
    width: 2108,
    height: 2775,
    customOverlay,
  },

  {
    src: `${url}/thumbnail/fidelion/71_2813x1632.jpg`,
    original: `${url}/origin/fidelion/71_2813x1632.jpg`,
    width: 2813,
    height: 1632,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/74_1536x768.jpg`,
    original: `${url}/origin/fidelion/74_1536x768.jpg`,
    width: 1536,
    height: 768,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/75_2208x2208.jpg`,
    original: `${url}/origin/fidelion/75_2208x2208.jpg`,
    width: 2208,
    height: 2208,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/76_4096x2318.jpg`,
    original: `${url}/origin/fidelion/76_4096x2318.jpg`,
    width: 4096,
    height: 2318,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/79_3900x2506.jpg`,
    original: `${url}/origin/fidelion/79_3900x2506.jpg`,
    width: 3900,
    height: 2506,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/81_4221x3686.jpg`,
    original: `${url}/origin/fidelion/81_4221x3686.jpg`,
    width: 4221,
    height: 3686,
    customOverlay,
  },

  {
    src: `${url}/thumbnail/fidelion/83_5000x3172.jpg`,
    original: `${url}/origin/fidelion/83_5000x3172.jpg`,
    width: 5000,
    height: 3172,
    customOverlay,
  },

  {
    src: `${url}/thumbnail/fidelion/86_1188x1200.jpg`,
    original: `${url}/origin/fidelion/86_1188x1200.jpg`,
    width: 1188,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/87_4325x1506.jpg`,
    original: `${url}/origin/fidelion/87_4325x1506.jpg`,
    width: 4325,
    height: 1506,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/90_4080x2404.jpg`,
    original: `${url}/origin/fidelion/90_4080x2404.jpg`,
    width: 4080,
    height: 2404,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/92_2846x1424.jpg`,
    original: `${url}/origin/fidelion/92_2846x1424.jpg`,
    width: 2846,
    height: 1424,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/93_5678x4000.jpg`,
    original: `${url}/origin/fidelion/93_5678x4000.jpg`,
    width: 5678,
    height: 4000,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/94_4096x1714.jpg`,
    original: `${url}/origin/fidelion/94_4096x1714.jpg`,
    width: 4096,
    height: 1714,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/95_2480x3508.jpg`,
    original: `${url}/origin/fidelion/95_2480x3508.jpg`,
    width: 2480,
    height: 3508,
    customOverlay,
  },

  {
    src: `${url}/thumbnail/fidelion/98_6223x4096.jpg`,
    original: `${url}/origin/fidelion/98_6223x4096.jpg`,
    width: 6223,
    height: 4096,
    customOverlay,
  },

  {
    src: `${url}/thumbnail/fidelion/100_3943x2179.jpg`,
    original: `${url}/origin/fidelion/100_3943x2179.jpg`,
    width: 3943,
    height: 2179,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/101_3365x4433.jpg`,
    original: `${url}/origin/fidelion/101_3365x4433.jpg`,
    width: 3365,
    height: 4433,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/102_2196x2192.jpg`,
    original: `${url}/origin/fidelion/102_2196x2192.jpg`,
    width: 2196,
    height: 2192,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/103_6000x3164.jpg`,
    original: `${url}/origin/fidelion/103_6000x3164.jpg`,
    width: 6000,
    height: 3164,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/104_2480x3508.jpg`,
    original: `${url}/origin/fidelion/104_2480x3508.jpg`,
    width: 2480,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/105_2480x3508.jpg`,
    original: `${url}/origin/fidelion/105_2480x3508.jpg`,
    width: 2480,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/106_2480x3508.jpg`,
    original: `${url}/origin/fidelion/106_2480x3508.jpg`,
    width: 2480,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/107_2480x3508.jpg`,
    original: `${url}/origin/fidelion/107_2480x3508.jpg`,
    width: 2480,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/108_1584x1584.jpg`,
    original: `${url}/origin/fidelion/108_1584x1584.jpg`,
    width: 1584,
    height: 1584,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/111_1741x1883.jpg`,
    original: `${url}/origin/fidelion/111_1741x1883.jpg`,
    width: 1741,
    height: 1883,
    customOverlay,
  },

  {
    src: `${url}/thumbnail/fidelion/116_2160x3840.jpg`,
    original: `${url}/origin/fidelion/116_2160x3840.jpg`,
    width: 2160,
    height: 3840,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/119_1653x1893.jpg`,
    original: `${url}/origin/fidelion/119_1653x1893.jpg`,
    width: 1653,
    height: 1893,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/120_6830x3004.jpg`,
    original: `${url}/origin/fidelion/120_6830x3004.jpg`,
    width: 6830,
    height: 3004,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/122_4096x2764.jpg`,
    original: `${url}/origin/fidelion/122_4096x2764.jpg`,
    width: 4096,
    height: 2764,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/124_6000x2291.jpg`,
    original: `${url}/origin/fidelion/124_6000x2291.jpg`,
    width: 6000,
    height: 2291,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/125_1387x1959.jpg`,
    original: `${url}/origin/fidelion/125_1387x1959.jpg`,
    width: 1387,
    height: 1959,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/126_1508x2160.jpg`,
    original: `${url}/origin/fidelion/126_1508x2160.jpg`,
    width: 1508,
    height: 2160,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/127_3668x4713.jpg`,
    original: `${url}/origin/fidelion/127_3668x4713.jpg`,
    width: 3668,
    height: 4713,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/128_1920x1080.jpg`,
    original: `${url}/origin/fidelion/128_1920x1080.jpg`,
    width: 1920,
    height: 1080,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/129_1701x2400.jpg`,
    original: `${url}/origin/fidelion/129_1701x2400.jpg`,
    width: 1701,
    height: 2400,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/130_1701x2400.jpg`,
    original: `${url}/origin/fidelion/130_1701x2400.jpg`,
    width: 1701,
    height: 2400,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/134_2846x1424.jpg`,
    original: `${url}/origin/fidelion/134_2846x1424.jpg`,
    width: 2846,
    height: 1424,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/135_2846x1424.jpg`,
    original: `${url}/origin/fidelion/135_2846x1424.jpg`,
    width: 2846,
    height: 1424,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/136_6000x2849.jpg`,
    original: `${url}/origin/fidelion/136_6000x2849.jpg`,
    width: 6000,
    height: 2849,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/137_1920x1080.jpg`,
    original: `${url}/origin/fidelion/137_1920x1080.jpg`,
    width: 1920,
    height: 1080,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/138_2276x2464.jpg`,
    original: `${url}/origin/fidelion/138_2276x2464.jpg`,
    width: 2276,
    height: 2464,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/140_1832x968.jpg`,
    original: `${url}/origin/fidelion/140_1832x968.jpg`,
    width: 1832,
    height: 968,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/141_4096x1434.jpg`,
    original: `${url}/origin/fidelion/141_4096x1434.jpg`,
    width: 4096,
    height: 1434,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/142_2054x2052.jpg`,
    original: `${url}/origin/fidelion/142_2054x2052.jpg`,
    width: 2054,
    height: 2052,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/143_1280x1280.jpg`,
    original: `${url}/origin/fidelion/143_1280x1280.jpg`,
    width: 1280,
    height: 1280,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/144_1916x1468.jpg`,
    original: `${url}/origin/fidelion/144_1916x1468.jpg`,
    width: 1916,
    height: 1468,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/145_8000x3022.jpg`,
    original: `${url}/origin/fidelion/145_8000x3022.jpg`,
    width: 8000,
    height: 3022,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/146_1682x1379.jpg`,
    original: `${url}/origin/fidelion/146_1682x1379.jpg`,
    width: 1682,
    height: 1379,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/147_1734x1183.jpg`,
    original: `${url}/origin/fidelion/147_1734x1183.jpg`,
    width: 1734,
    height: 1183,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/148_4096x2038.jpg`,
    original: `${url}/origin/fidelion/148_4096x2038.jpg`,
    width: 4096,
    height: 2038,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/149_1280x1280.jpg`,
    original: `${url}/origin/fidelion/149_1280x1280.jpg`,
    width: 1280,
    height: 1280,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/150_1784x1088.jpg`,
    original: `${url}/origin/fidelion/150_1784x1088.jpg`,
    width: 1784,
    height: 1088,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/151_678x1900.jpg`,
    original: `${url}/origin/fidelion/151_678x1900.jpg`,
    width: 678,
    height: 1900,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/152_6000x2000.jpg`,
    original: `${url}/origin/fidelion/152_6000x2000.jpg`,
    width: 6000,
    height: 2000,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/153_1776x1024.jpg`,
    original: `${url}/origin/fidelion/153_1776x1024.jpg`,
    width: 1776,
    height: 1024,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/154_1536x768.jpg`,
    original: `${url}/origin/fidelion/154_1536x768.jpg`,
    width: 1536,
    height: 768,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/155_2518x1948.jpg`,
    original: `${url}/origin/fidelion/155_2518x1948.jpg`,
    width: 2518,
    height: 1948,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/158_1557x443.jpg`,
    original: `${url}/origin/fidelion/158_1557x443.jpg`,
    width: 1557,
    height: 443,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/159_3000x1668.jpg`,
    original: `${url}/origin/fidelion/159_3000x1668.jpg`,
    width: 3000,
    height: 1668,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/160_1945x1853.jpg`,
    original: `${url}/origin/fidelion/160_1945x1853.jpg`,
    width: 1945,
    height: 1853,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/161_6000x4191.jpg`,
    original: `${url}/origin/fidelion/161_6000x4191.jpg`,
    width: 6000,
    height: 4191,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/163_1354x1918.jpg`,
    original: `${url}/origin/fidelion/163_1354x1918.jpg`,
    width: 1354,
    height: 1918,
    customOverlay,
  },

  {
    src: `${url}/thumbnail/fidelion/165_1333x1605.jpg`,
    original: `${url}/origin/fidelion/165_1333x1605.jpg`,
    width: 1333,
    height: 1605,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/166_1610x281.jpg`,
    original: `${url}/origin/fidelion/166_1610x281.jpg`,
    width: 1610,
    height: 281,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/167_7000x4657.jpg`,
    original: `${url}/origin/fidelion/167_7000x4657.jpg`,
    width: 7000,
    height: 4657,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/168_1204x976.jpg`,
    original: `${url}/origin/fidelion/168_1204x976.jpg`,
    width: 1204,
    height: 976,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/169_1387x1958.jpg`,
    original: `${url}/origin/fidelion/169_1387x1958.jpg`,
    width: 1387,
    height: 1958,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/170_999x410.jpg`,
    original: `${url}/origin/fidelion/170_999x410.jpg`,
    width: 999,
    height: 410,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/171_3422x1555.jpg`,
    original: `${url}/origin/fidelion/171_3422x1555.jpg`,
    width: 3422,
    height: 1555,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/172_1456x816.jpg`,
    original: `${url}/origin/fidelion/172_1456x816.jpg`,
    width: 1456,
    height: 816,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/175_1638x4096.jpg`,
    original: `${url}/origin/fidelion/175_1638x4096.jpg`,
    width: 1638,
    height: 4096,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/176_4096x2938.jpg`,
    original: `${url}/origin/fidelion/176_4096x2938.jpg`,
    width: 4096,
    height: 2938,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/177_4096x3264.jpg`,
    original: `${url}/origin/fidelion/177_4096x3264.jpg`,
    width: 4096,
    height: 3264,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/178_2845x1544.jpg`,
    original: `${url}/origin/fidelion/178_2845x1544.jpg`,
    width: 2845,
    height: 1544,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/179_1574x842.jpg`,
    original: `${url}/origin/fidelion/179_1574x842.jpg`,
    width: 1574,
    height: 842,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/180_1448x2052.jpg`,
    original: `${url}/origin/fidelion/180_1448x2052.jpg`,
    width: 1448,
    height: 2052,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/181_1600x1600.jpg`,
    original: `${url}/origin/fidelion/181_1600x1600.jpg`,
    width: 1600,
    height: 1600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/182_1600x1600.jpg`,
    original: `${url}/origin/fidelion/182_1600x1600.jpg`,
    width: 1600,
    height: 1600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/183_1508x626.jpg`,
    original: `${url}/origin/fidelion/183_1508x626.jpg`,
    width: 1508,
    height: 626,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/184_1600x1600.jpg`,
    original: `${url}/origin/fidelion/184_1600x1600.jpg`,
    width: 1600,
    height: 1600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/185_1600x1600.jpg`,
    original: `${url}/origin/fidelion/185_1600x1600.jpg`,
    width: 1600,
    height: 1600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/186_1600x1600.jpg`,
    original: `${url}/origin/fidelion/186_1600x1600.jpg`,
    width: 1600,
    height: 1600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/187_1600x1600.jpg`,
    original: `${url}/origin/fidelion/187_1600x1600.jpg`,
    width: 1600,
    height: 1600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/188_3480x1456.jpg`,
    original: `${url}/origin/fidelion/188_3480x1456.jpg`,
    width: 3480,
    height: 1456,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/189_3480x1456.jpg`,
    original: `${url}/origin/fidelion/189_3480x1456.jpg`,
    width: 3480,
    height: 1456,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/190_1292x2190.jpg`,
    original: `${url}/origin/fidelion/190_1292x2190.jpg`,
    width: 1292,
    height: 2190,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/194_2480x3508.jpg`,
    original: `${url}/origin/fidelion/194_2480x3508.jpg`,
    width: 2480,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/195_2480x3508.jpg`,
    original: `${url}/origin/fidelion/195_2480x3508.jpg`,
    width: 2480,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/196_4096x3544.jpg`,
    original: `${url}/origin/fidelion/196_4096x3544.jpg`,
    width: 4096,
    height: 3544,
    customOverlay,
  },

  {
    src: `${url}/thumbnail/fidelion/198_4096x2471.jpg`,
    original: `${url}/origin/fidelion/198_4096x2471.jpg`,
    width: 4096,
    height: 2471,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/199_4096x4055.jpg`,
    original: `${url}/origin/fidelion/199_4096x4055.jpg`,
    width: 4096,
    height: 4055,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/200_300x341.jpg`,
    original: `${url}/origin/fidelion/200_300x341.gif`,
    width: 300,
    height: 341,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/201_1404x470.jpg`,
    original: `${url}/origin/fidelion/201_1404x470.jpg`,
    width: 1404,
    height: 470,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/202_4096x743.jpg`,
    original: `${url}/origin/fidelion/202_4096x743.jpg`,
    width: 4096,
    height: 743,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/203_814x507.jpg`,
    original: `${url}/origin/fidelion/203_814x507.jpg`,
    width: 814,
    height: 507,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/204_1422x915.jpg`,
    original: `${url}/origin/fidelion/204_1422x915.jpg`,
    width: 1422,
    height: 915,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/206_2480x3508.jpg`,
    original: `${url}/origin/fidelion/206_2480x3508.jpg`,
    width: 2480,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/207_2480x3508.jpg`,
    original: `${url}/origin/fidelion/207_2480x3508.jpg`,
    width: 2480,
    height: 3508,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/fidelion/208_719x674.jpg`,
    original: `${url}/origin/fidelion/208_719x674.jpg`,
    width: 719,
    height: 674,
    customOverlay,
  },
];

export const blackMarketImages: CustomImage[] = [
  {
    src: `${url}/thumbnail/blackmarket/1_2880x1800.jpg`,
    original: `${url}/origin/blackmarket/1_2880x1800.jpg`,
    width: 2880,
    height: 1800,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/2_1426x900.jpg`,
    original: `${url}/origin/blackmarket/2_1426x900.jpg`,
    width: 1426,
    height: 900,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/4_5002x7856.jpg`,
    original: `${url}/origin/blackmarket/4_5002x7856.jpg`,
    width: 5002,
    height: 7856,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/5_5078x7630.jpg`,
    original: `${url}/origin/blackmarket/5_5078x7630.jpg`,
    width: 5078,
    height: 7630,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/6_548x337.jpg`,
    original: `${url}/origin/blackmarket/6_548x337.webp`,
    width: 548,
    height: 337,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/8_1000x1000.jpg`,
    original: `${url}/origin/blackmarket/8_1000x1000.jpg`,
    width: 1000,
    height: 1000,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/9_2464x2198.jpg`,
    original: `${url}/origin/blackmarket/9_2464x2198.jpg`,
    width: 2464,
    height: 2198,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/11_2400x2400.jpg`,
    original: `${url}/origin/blackmarket/11_2400x2400.jpg`,
    width: 2400,
    height: 2400,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/12_2400x2400.jpg`,
    original: `${url}/origin/blackmarket/12_2400x2400.jpg`,
    width: 2400,
    height: 2400,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/13_2400x2400.jpg`,
    original: `${url}/origin/blackmarket/13_2400x2400.jpg`,
    width: 2400,
    height: 2400,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/14_2400x2400.jpg`,
    original: `${url}/origin/blackmarket/14_2400x2400.jpg`,
    width: 2400,
    height: 2400,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/15_2400x2400.jpg`,
    original: `${url}/origin/blackmarket/15_2400x2400.jpg`,
    width: 2400,
    height: 2400,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/16_2400x2400.jpg`,
    original: `${url}/origin/blackmarket/16_2400x2400.jpg`,
    width: 2400,
    height: 2400,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/17_2400x2400.jpg`,
    original: `${url}/origin/blackmarket/17_2400x2400.jpg`,
    width: 2400,
    height: 2400,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/18_2400x2400.jpg`,
    original: `${url}/origin/blackmarket/18_2400x2400.jpg`,
    width: 2400,
    height: 2400,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/19_2400x2400.jpg`,
    original: `${url}/origin/blackmarket/19_2400x2400.jpg`,
    width: 2400,
    height: 2400,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/20_1920x1920.jpg`,
    original: `${url}/origin/blackmarket/20_1920x1920.jpg`,
    width: 1920,
    height: 1920,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/21_1920x1920.jpg`,
    original: `${url}/origin/blackmarket/21_1920x1920.jpg`,
    width: 1920,
    height: 1920,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/22_1920x1920.jpg`,
    original: `${url}/origin/blackmarket/22_1920x1920.jpg`,
    width: 1920,
    height: 1920,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/23_1920x1920.jpg`,
    original: `${url}/origin/blackmarket/23_1920x1920.jpg`,
    width: 1920,
    height: 1920,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/24_1920x1920.jpg`,
    original: `${url}/origin/blackmarket/24_1920x1920.jpg`,
    width: 1920,
    height: 1920,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/25_1920x1920.jpg`,
    original: `${url}/origin/blackmarket/25_1920x1920.jpg`,
    width: 1920,
    height: 1920,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/26_1920x1920.jpg`,
    original: `${url}/origin/blackmarket/26_1920x1920.jpg`,
    width: 1920,
    height: 1920,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/27_1920x1920.jpg`,
    original: `${url}/origin/blackmarket/27_1920x1920.jpg`,
    width: 1920,
    height: 1920,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/28_1920x1920.jpg`,
    original: `${url}/origin/blackmarket/28_1920x1920.jpg`,
    width: 1920,
    height: 1920,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/29_600x600.jpg`,
    original: `${url}/origin/blackmarket/29_600x600.jpg`,
    width: 600,
    height: 600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/30_600x600.jpg`,
    original: `${url}/origin/blackmarket/30_600x600.jpg`,
    width: 600,
    height: 600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/31_600x600.jpg`,
    original: `${url}/origin/blackmarket/31_600x600.jpg`,
    width: 600,
    height: 600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/32_600x600.jpg`,
    original: `${url}/origin/blackmarket/32_600x600.jpg`,
    width: 600,
    height: 600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/33_600x600.jpg`,
    original: `${url}/origin/blackmarket/33_600x600.jpg`,
    width: 600,
    height: 600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/34_600x600.jpg`,
    original: `${url}/origin/blackmarket/34_600x600.jpg`,
    width: 600,
    height: 600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/35_600x600.jpg`,
    original: `${url}/origin/blackmarket/35_600x600.jpg`,
    width: 600,
    height: 600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/36_600x600.jpg`,
    original: `${url}/origin/blackmarket/36_600x600.jpg`,
    width: 600,
    height: 600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/blackmarket/37_600x600.jpg`,
    original: `${url}/origin/blackmarket/37_600x600.jpg`,
    width: 600,
    height: 600,
    customOverlay,
  },
];

export const megaroadImages: CustomImage[] = [
  {
    src: `${url}/thumbnail/megaroad/2_1500x1500.jpg`,
    original: `${url}/origin/megaroad/2_1500x1500.jpg`,
    width: 1500,
    height: 1500,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/3_900x1600.jpg`,
    original: `${url}/origin/megaroad/3_900x1600.jpg`,
    width: 900,
    height: 1600,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/4_1515x850.jpg`,
    original: `${url}/origin/megaroad/4_1515x850.jpg`,
    width: 1515,
    height: 850,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/5_4096x1505.jpg`,
    original: `${url}/origin/megaroad/5_4096x1505.jpg`,
    width: 4096,
    height: 1505,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/6_4096x2197.jpg`,
    original: `${url}/origin/megaroad/6_4096x2197.jpg`,
    width: 4096,
    height: 2197,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/7_4096x1083.jpg`,
    original: `${url}/origin/megaroad/7_4096x1083.jpg`,
    width: 4096,
    height: 1083,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/8_1117x1784.jpg`,
    original: `${url}/origin/megaroad/8_1117x1784.jpg`,
    width: 1117,
    height: 1784,
    customOverlay,
  },

  {
    src: `${url}/thumbnail/megaroad/11_1024x1024.jpg`,
    original: `${url}/origin/megaroad/11_1024x1024.jpg`,
    width: 1024,
    height: 1024,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/12_1024x1024.jpg`,
    original: `${url}/origin/megaroad/12_1024x1024.jpg`,
    width: 1024,
    height: 1024,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/13_1024x1024.jpg`,
    original: `${url}/origin/megaroad/13_1024x1024.jpg`,
    width: 1024,
    height: 1024,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/14_1024x1024.jpg`,
    original: `${url}/origin/megaroad/14_1024x1024.jpg`,
    width: 1024,
    height: 1024,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/15_1024x1024.jpg`,
    original: `${url}/origin/megaroad/15_1024x1024.jpg`,
    width: 1024,
    height: 1024,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/16_3946x1592.jpg`,
    original: `${url}/origin/megaroad/16_3946x1592.jpg`,
    width: 3946,
    height: 1592,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/17_3946x1592.jpg`,
    original: `${url}/origin/megaroad/17_3946x1592.jpg`,
    width: 3946,
    height: 1592,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/18_3946x1592.jpg`,
    original: `${url}/origin/megaroad/18_3946x1592.jpg`,
    width: 3946,
    height: 1592,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/19_3946x1592.jpg`,
    original: `${url}/origin/megaroad/19_3946x1592.jpg`,
    width: 3946,
    height: 1592,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/20_3946x1592.jpg`,
    original: `${url}/origin/megaroad/20_3946x1592.jpg`,
    width: 3946,
    height: 1592,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/21_3946x1592.jpg`,
    original: `${url}/origin/megaroad/21_3946x1592.jpg`,
    width: 3946,
    height: 1592,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/22_1078x1918.jpg`,
    original: `${url}/origin/megaroad/22_1078x1918.jpg`,
    width: 1078,
    height: 1918,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/23_2048x2048.jpg`,
    original: `${url}/origin/megaroad/23_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/24_2048x2048.jpg`,
    original: `${url}/origin/megaroad/24_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/25_2048x2048.jpg`,
    original: `${url}/origin/megaroad/25_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/26_2048x2048.jpg`,
    original: `${url}/origin/megaroad/26_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/27_2048x2048.jpg`,
    original: `${url}/origin/megaroad/27_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/28_2048x2048.jpg`,
    original: `${url}/origin/megaroad/28_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/29_2048x2048.jpg`,
    original: `${url}/origin/megaroad/29_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/30_2048x2048.jpg`,
    original: `${url}/origin/megaroad/30_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/31_2048x2048.jpg`,
    original: `${url}/origin/megaroad/31_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/32_2048x2048.jpg`,
    original: `${url}/origin/megaroad/32_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/33_2048x2048.jpg`,
    original: `${url}/origin/megaroad/33_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/34_2048x2048.jpg`,
    original: `${url}/origin/megaroad/34_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/37_1193x1642.jpg`,
    original: `${url}/origin/megaroad/37_1193x1642.jpg`,
    width: 1193,
    height: 1642,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/38_1193x1642.jpg`,
    original: `${url}/origin/megaroad/38_1193x1642.jpg`,
    width: 1193,
    height: 1642,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/40_2048x2048.jpg`,
    original: `${url}/origin/megaroad/40_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/42_2048x2048.jpg`,
    original: `${url}/origin/megaroad/42_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/43_2048x2048.jpg`,
    original: `${url}/origin/megaroad/43_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/45_2048x2048.jpg`,
    original: `${url}/origin/megaroad/45_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/46_2048x2048.jpg`,
    original: `${url}/origin/megaroad/46_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/47_2048x2048.jpg`,
    original: `${url}/origin/megaroad/47_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/48_2048x2048.jpg`,
    original: `${url}/origin/megaroad/48_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/49_2048x2048.jpg`,
    original: `${url}/origin/megaroad/49_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/50_2048x2048.jpg`,
    original: `${url}/origin/megaroad/50_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/51_2048x2048.jpg`,
    original: `${url}/origin/megaroad/51_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/53_2048x2048.jpg`,
    original: `${url}/origin/megaroad/53_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/54_2048x2048.jpg`,
    original: `${url}/origin/megaroad/54_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/55_2048x2048.jpg`,
    original: `${url}/origin/megaroad/55_2048x2048.jpg`,
    width: 2048,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/56_1024x2048.jpg`,
    original: `${url}/origin/megaroad/56_1024x2048.jpg`,
    width: 1024,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/57_1024x2048.jpg`,
    original: `${url}/origin/megaroad/57_1024x2048.jpg`,
    width: 1024,
    height: 2048,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/58_1000x1374.jpg`,
    original: `${url}/origin/megaroad/58_1000x1374.jpg`,
    width: 1000,
    height: 1374,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/59_1000x1374.jpg`,
    original: `${url}/origin/megaroad/59_1000x1374.jpg`,
    width: 1000,
    height: 1374,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/60_1000x1374.jpg`,
    original: `${url}/origin/megaroad/60_1000x1374.jpg`,
    width: 1000,
    height: 1374,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/61_1000x1374.jpg`,
    original: `${url}/origin/megaroad/61_1000x1374.jpg`,
    width: 1000,
    height: 1374,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/62_1290x2796.jpg`,
    original: `${url}/origin/megaroad/62_1290x2796.jpg`,
    width: 1290,
    height: 2796,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/63_1290x2796.jpg`,
    original: `${url}/origin/megaroad/63_1290x2796.jpg`,
    width: 1290,
    height: 2796,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/64_1290x2796.jpg`,
    original: `${url}/origin/megaroad/64_1290x2796.jpg`,
    width: 1290,
    height: 2796,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/65_1290x2796.jpg`,
    original: `${url}/origin/megaroad/65_1290x2796.jpg`,
    width: 1290,
    height: 2796,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/66_1290x2796.jpg`,
    original: `${url}/origin/megaroad/66_1290x2796.jpg`,
    width: 1290,
    height: 2796,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/67_1200x628.jpg`,
    original: `${url}/origin/megaroad/67_1200x628.jpg`,
    width: 1200,
    height: 628,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/68_960x1200.jpg`,
    original: `${url}/origin/megaroad/68_960x1200.jpg`,
    width: 960,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/69_1200x1200.jpg`,
    original: `${url}/origin/megaroad/69_1200x1200.jpg`,
    width: 1200,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/70_960x1200.jpg`,
    original: `${url}/origin/megaroad/70_960x1200.jpg`,
    width: 960,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/71_960x1200.jpg`,
    original: `${url}/origin/megaroad/71_960x1200.jpg`,
    width: 960,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/72_960x1200.jpg`,
    original: `${url}/origin/megaroad/72_960x1200.jpg`,
    width: 960,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/73_960x1200.jpg`,
    original: `${url}/origin/megaroad/73_960x1200.jpg`,
    width: 960,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/74_1200x1200.jpg`,
    original: `${url}/origin/megaroad/74_1200x1200.jpg`,
    width: 1200,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/75_1200x628.jpg`,
    original: `${url}/origin/megaroad/75_1200x628.jpg`,
    width: 1200,
    height: 628,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/76_960x1200.jpg`,
    original: `${url}/origin/megaroad/76_960x1200.jpg`,
    width: 960,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/77_1200x1200.jpg`,
    original: `${url}/origin/megaroad/77_1200x1200.jpg`,
    width: 1200,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/78_960x1200.jpg`,
    original: `${url}/origin/megaroad/78_960x1200.jpg`,
    width: 960,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/79_1549x1550.jpg`,
    original: `${url}/origin/megaroad/79_1549x1550.jpg`,
    width: 1549,
    height: 1550,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/80_960x1200.jpg`,
    original: `${url}/origin/megaroad/80_960x1200.jpg`,
    width: 960,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/81_1200x1200.jpg`,
    original: `${url}/origin/megaroad/81_1200x1200.jpg`,
    width: 1200,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/82_960x1200.jpg`,
    original: `${url}/origin/megaroad/82_960x1200.jpg`,
    width: 960,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/83_960x1200.jpg`,
    original: `${url}/origin/megaroad/83_960x1200.jpg`,
    width: 960,
    height: 1200,
    customOverlay,
  },
  {
    src: `${url}/thumbnail/megaroad/84_960x1200.jpg`,
    original: `${url}/origin/megaroad/84_960x1200.jpg`,
    width: 960,
    height: 1200,
    customOverlay,
  },
];
