import styles from './SnsButton.module.css';
interface Props {
  icon: string;
  alt: string;
  url: string;
}
function SnsButton(props: Props) {
  const { icon, alt, url } = props;
  return (
    <button
      className={styles.button}
      onClick={() => window.open(url, '__blank')}
    >
      <img src={icon} alt={alt} />
    </button>
  );
}

export default SnsButton;
