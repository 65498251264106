import { Gallery as GridGallery } from 'react-grid-gallery';
import { CustomImage } from 'Page/galleryImage';
import './GalleryGridView.css';
import { useMediaQuery } from 'react-responsive';
import Lightbox from 'react-image-lightbox';
import { useState } from 'react';
interface Props {
  images: CustomImage[];
  nextImages: CustomImage[];
}
function GalleryGridView(props: Props) {
  const { images } = props;
  const [index, setIndex] = useState(-1);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const nextIndex = (index + 1) % images.length;
  const prevIndex = (index + images.length - 1) % images.length;

  const handleClick = (index: number, item: CustomImage) => {
    setIsOpenModal(!isOpenModal);
    setIndex(index);
  };
  const handleClose = () => {
    setIndex(-1);
    setIsOpenModal(!isOpenModal);
  };
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const isMobile = useMediaQuery({
    query: '(max-width: 1024px)',
  });
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <GridGallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
        rowHeight={isMobile ? 200 : 300}
        margin={5}
      />
      {isOpenModal && (
        <Lightbox
          imageLoadErrorMessage={'image loading failed'}
          enableZoom={false}
          mainSrc={images[index].original}
          nextSrc={images[nextIndex].original}
          prevSrc={images[prevIndex].original}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
}

export default GalleryGridView;
