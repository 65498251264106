import 'react-image-lightbox/style.css';
import {
  blackMarketImages,
  fidelionImages,
  megaroadImages,
} from '../galleryImage';
import GalleryGridView from 'components/GalleryGridView/GalleryGridView';
import GalleryTitle from 'components/GalleryTitle/GalleryTitle';
import styles from './Gallery.module.css';
import commonStyles from 'common.module.css';
import fidelionTitle from 'assets/images/gallery-fidelion-title.png';
import blackmarketTitle from 'assets/images/gallery-blackmarket-title.png';
import megaroadTitle from 'assets/images/gallery-megaroad-title.png';

function Gallery() {
  return (
    <div className={`${commonStyles.page} ${styles.gallery}`}>
      <div style={{ height: '1px' }} />
      <section className={styles.section}>
        <GalleryTitle titleImage={fidelionTitle} />
        <GalleryGridView
          images={fidelionImages}
          nextImages={blackMarketImages}
        />
      </section>
      <section className={styles.section}>
        <GalleryTitle titleImage={blackmarketTitle} />
        <GalleryGridView
          images={blackMarketImages}
          nextImages={megaroadImages}
        />
      </section>
      <section className={styles.section}>
        <GalleryTitle titleImage={megaroadTitle} />
        <GalleryGridView images={megaroadImages} nextImages={fidelionImages} />
      </section>
    </div>
  );
}

export default Gallery;
