import HomeSection from 'components/HomeSection/HomeSection';
import { useEffect, useLayoutEffect, useState } from 'react';

import fidelionBG from 'assets/images/fidelion-bg.jpg';
import blackmarketBG from 'assets/images/blackmarket-bg.jpg';
import marketplaceBG from 'assets/images/marketplace-bg.jpg';
import megaroadBG from 'assets/images/megaroad-bg.jpg';

import fidelionMBG from 'assets/images/fidelion-m-bg.jpg';
import blackmarketMBG from 'assets/images/blackmarket-m-bg.jpg';
import marketplaceMBG from 'assets/images/marketplace-m-bg.jpg';
import megaroadMBG from 'assets/images/megaroad-m-bg.jpg';

import fidelionTitle from 'assets/images/fidelion-title.svg';
import blackMarketTitle from 'assets/images/blackmarket-title.svg';
import marketplaceTitle from 'assets/images/marketplace-title.svg';
import megaroadTitle from 'assets/images/megaroad-title.svg';

import fidelionPG from 'assets/images/fidelion-pg.jpg';
import blackmarketPG from 'assets/images/blackmarket-pg.jpg';
import marketplacePG from 'assets/images/marketplace-pg.jpg';
import megaroadPG from 'assets/images/megaroad-pg.jpg';

import styles from './Home.module.css';
import commonStyles from 'common.module.css';
import { HomeSectionInfo } from 'type';

interface Periods {
  startDate: string;
  endDate: string;
}

interface PeriodsResponse {
  airdrop: Periods;
  raffle: Periods;
}

export const sections = [
  {
    title: 'fidelion',
    titleImage: fidelionTitle,
    backgroundImage: fidelionBG,
    mobileBackgroundImage: fidelionMBG,
    progressiveImage: fidelionPG,
    description:
      'Your favorite dark fantasy\n' +
      'saga of tomorrow. \n' +
      'A dieselpunk franchise that\n combines PFP values and\n gaming under a single\n intellectual property.',
    mobileDescription:
      'Your favorite dark fantasy saga of tomorrow\n' +
      ' A dieselpunk franchise that combines PFP values and\n' +
      'gaming under a single intellectual property.',
    url: 'https://story.fidelion.io',
  },
  {
    title: 'blackmarket',
    titleImage: blackMarketTitle,
    backgroundImage: blackmarketBG,
    mobileBackgroundImage: blackmarketMBG,
    progressiveImage: blackmarketPG,
    description: 'Loot items from the factions\n' + 'and earn $2080.',
    url: 'https://blackmarket.fidelion.io/',
  },
  {
    title: 'marketplace',
    titleImage: marketplaceTitle,
    backgroundImage: marketplaceBG,
    mobileBackgroundImage: marketplaceMBG,
    progressiveImage: marketplacePG,
    description:
      'Fidelion-friendly marketplace to buy\n' +
      'and sell Fidelion NFT with perks.',
    url: 'https://marketplace.fidelion.io/#/marketplace',
  },
  {
    title: 'megaroad',
    titleImage: megaroadTitle,
    backgroundImage: megaroadBG,
    mobileBackgroundImage: megaroadMBG,
    progressiveImage: megaroadPG,
    description:
      'Join Willi, Jeffery,\n' +
      'and Mobi on their\n search for Mega Road.\n' +
      'The road to their \n dream paradise on\n the seemingly\n doomed earth.',
    mobileDescription:
      'Join Willi, Jeffery, and Mobi on their search for Mega Road.\n' +
      'The road to their dream paradise on the seemingly doomed earth.',
    url: 'https://megaroad.io/',
  },
];

function Home() {
  const [hoveredSection, setHoveredSection] = useState<HomeSectionInfo>(
    sections[0],
  );

  useLayoutEffect(() => {
    const home = document.getElementById('home');
    if (!home) {
      return;
    }
    home.style.backgroundImage = `url(${hoveredSection.backgroundImage})`;
  }, [hoveredSection]);

  useEffect(() => {
    document.addEventListener('touchmove', e => {
      return false;
    });
  }, []);

  return (
    <div
      id='home'
      className={`${commonStyles.page} ${styles.home}`}
      style={{ background: hoveredSection.progressiveImage, width: '100vw' }}
    >
      {sections.map(item => (
        <HomeSection
          key={item.title}
          info={item}
          hoveredSection={hoveredSection}
          onHover={setHoveredSection}
        />
      ))}
    </div>
  );
}

export default Home;
