import styles from './GalleryTitle.module.css';

interface Props {
  titleImage: string;
}
function GalleryTitle(props: Props) {
  const { titleImage } = props;
  return (
    <div className={styles.container}>
      <img
        className={styles.title}
        src={titleImage}
        style={{ width: '500px' }}
        alt='title'
      />
    </div>
  );
}

export default GalleryTitle;
